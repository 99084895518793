// 全局axios
import axios from 'axios'
import Vue from 'vue'
// 全局请求
Vue.$httpRequestList = []
// 请求前的拦截
axios.interceptors.request.use(
	config => {
		// 配置请求的根路径
		// config.baseURL = 'http://' + window.location.host
		config.baseURL = '/admin'
		// 每个请求都会 加入
		config.cancelToken = new axios.CancelToken(cancel => {
			Vue.$httpRequestList.push({
				cancel
			})
		})
		if (config.headers.showLoading !== false) {
			Vue.prototype.$showScreenLoading(config.headers);
		}
		return config
	},
	error => {
		Vue.prototype.$message.error('请求超时');
		return Promise.reject(error)
	}
)
// 请求后的拦截
axios.interceptors.response.use(
	response => {
		if (response.status === 200) {
			if (response.config.headers.showLoading !== false) {
				Vue.prototype.$hideScreenLoading();
			}
			if (response.data.code !== 0) {
				Vue.prototype.$message.error('请求失败:' + response.data.msg);
				if (response.data.code === -1) {
					// setTimeout(() => {
					// 	window.location.href = '/weixin/index'
					// }, 1000)
				}
			}
			return response.data
		}
	},
	error => {
		if (error.response.config.headers.showLoading !== false) {
			Vue.prototype.$hideScreenLoading();
		}
		if (error.message.includes('timeout')) { // 判断请求异常信息中是否含有超时timeout字符串
			Vue.prototype.$message.error('网络超时');
		}
		if (error.response) {
			if (error.response.status === 500 || error.response.status === 502) {
				Vue.prototype.$message.error('服务异常');
			} else if (error.response.status === 404) {
				Vue.prototype.$message.error('请求异常');
			} else if (error.response.status === 401) {
				// window.location.href = '/weixin/index'
			} else {
				Vue.prototype.$message.error('未知错误');
			}
		}
		return Promise.reject(error.response)
	}
)
// axios请求
const axiosRequest = (url, method, params = {}, timeout, showLoading) => {
	return new Promise((resolve, reject) => {
		axios({
			url: url,
			method: method,
			params: params,
			timeout: timeout,
			headers: {
				showLoading: showLoading
			}
		}).then(data => {
			resolve(data)
		}).catch((error) => {
			resolve(error)
		})
	})
}
const axiosRequestFormData = (url, method, data, timeout, title) => {
	return new Promise((resolve, reject) => {
		axios({
			url: url,
			method: method,
			data: data,
			timeout: timeout,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		}).then(data => {
			resolve(data)
		}).catch((error) => {
			resolve(error)
		})
	})
}
export default {
	login: (config) => axiosRequest('pc/login', 'post', config, 10000),
	// 公用信息
	getInfoList: (config) => axiosRequest('msg/message/info', 'get', config, 10000),
	// 判断登录过期
	actionCollect: (config) => axiosRequest('action-collect', 'get', config, 10000, false),
	// 教师主页
	T_getTeacherInfo: (config) => axiosRequest('teacher/info', 'get', config, 10000, true),
	T_getStudentList: (config) => axiosRequest('teacher/student', 'get', config, 10000, true),
	T_studentSave: (config) => axiosRequest('teacher/student/save', 'post', config, 10000, true)
}
