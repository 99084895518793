import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../pages/Login.vue'
// import Entrance from '../pages/entrance/Entrance.vue'
// //教师
// import T_Home from '../pages/teacher/home/Home.vue'
// import T_Student from '../pages/teacher/student/Student.vue'
// import T_ParentBinding from '../pages/teacher/student/ParentBinding.vue'
// import Home from '../components/Home.vue'
// import Welcome from '../components/Welcome.vue'
// import Users from '../components/user/Users.vue'
// import Rights from '../components/power/Rights.vue'
// import Roles from '../components/power/Roles.vue'
// import Cate from '../components/goods/Cate.vue'
// import Params from '../components/goods/Params.vue'
// import GoodsList from '../components/goods/List.vue'
// import Add from '../components/goods/Add.vue'
// import Order from '../components/order/Order.vue'
// import Report from '../components/report/Report.vue'
Vue.use(VueRouter)

const routes = [{
		path: '/',
		redirect: '/login'
	},
	{
		path: '/login',
		component: Login
	}
	// ,
	// {
	// 	path: '/entrance',
	// 	component: Entrance
	// },
	// //主页
	// {
	// 	path: '/',
	// 	component: T_Home,
	// 	redirect: '/teacher/student',
	// 	meta: {
	// 		title: "管理学生"
	// 	},
	// 	children: [{
	// 		path: "/teacher/student",
	// 		component: T_Student,
	// 		meta: {
	// 			title: "学生管理"
	// 		}
	// 	}]
	// },
	// {
	// 	path: '/',
	// 	component: T_Home,
	// 	meta: {
	// 		title: "权限管理"
	// 	},
	// 	children: [{
	// 		path: "/teacher/parent/binding",
	// 		component: T_ParentBinding,
	// 		meta: {
	// 			title: "角色列表"
	// 		}
	// 	}]
	// }
]

const router = new VueRouter({
	routes
})
router.beforeEach((to, from, next) => {
	if (to.path === '/login') return next()
	// const tokenStr = window.sessionStorage.getItem('token')
	// if (!tokenStr) return next('/login')
	next()
})
export default router
