// import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import './assets/css/global.css'
import './assets/css/login.css'
// 导入字体图标
import './assets/fonts/iconfont.css'
// 配置全局loading
import { showScreenLoading, hideScreenLoading } from "./loading"
Vue.prototype.$showScreenLoading = showScreenLoading
Vue.prototype.$hideScreenLoading = hideScreenLoading
// 配置请求的根路径axios
import service from './service'
Vue.prototype.$service = service
Vue.config.productionTip = false
// 动态修改titile
const defaultTitle = '吾爱吾加智慧校园'
router.beforeEach((to, from, next) => {
	Vue.$httpRequestList.forEach((el, index) => {
		el.cancel() // 路由跳转之前，中止请求
		Vue.prototype.$message.closeAll();
		Vue.prototype.$hideScreenLoading();
		// 重置 Vue.$httpRequestList
		delete Vue.$httpRequestList[index]
	})
	async function actionCollect() { 
		await Vue.prototype.$service.actionCollect({})
	}
	actionCollect()
	document.title = to.meta.title ? to.meta.title : defaultTitle
	next()
})
// import TreeTable from 'vue-table-with-tree-grid'
// import VueQuillEditor from 'vue-quill-editor'
// import 'quill/dist/quill.core.css'
// import 'quill/dist/quill.snow.css' 
// import 'quill/dist/quill.bubble.css'

// axios.defaults.baseURL=`http://127.0.0.1:8888/api/private/v1/`
// axios.interceptors.request.use(config=>{
//   config.headers.Authorization=window.sessionStorage.getItem('token')
//   return config
// })
// Vue.prototype.$http=axios

// Vue.config.productionTip = false

// Vue.component('tree-table',TreeTable)
// Vue.use(VueQuillEditor)
// Vue.filter('dateFormat',function(originVal){
//   const dt=new Date(originVal)
//   const y=dt.getFullYear()
//   const m=(dt.getMonth()+1+'').padStart(2,'0')
//   const d=(dt.getDate()+'').padStart(2,'0')
//   const hh=(dt.getHours()+'').padStart(2,'0')
//   const mm=(dt.getMinutes()+'').padStart(2,'0')
//   const ss=(dt.getSeconds()+'').padStart(2,'0')
//   return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
// })
new Vue({
	router,
	render: h => h(App)
}).$mount('#app')
