<template>
	<div class="login_container">
		<div class="login_box">
			<!-- 头像区域 -->
			<div class="avatar_box"><img src="http://kf.wiwjschool.com/static/assets/images/logo.png" alt="" /></div>
			<!-- 登录表单区域 -->
			<el-form ref="loginFormRef" :model="loginForm" :rules="loginFormRules" label-width="0px" class="login_form">
				<!-- 用户名 -->
				<el-form-item prop="username">
					<el-input
						v-model="loginForm.username"
						placeholder="请输入用户名"
						prefix-icon="iconfont icon-user"
					></el-input>
				</el-form-item>
				<!-- 密码 -->
				<el-form-item prop="password">
					<el-input
						v-model="loginForm.password"
						placeholder="请输入密码"
						prefix-icon="iconfont icon-3702mima"
						type="password"
					></el-input>
				</el-form-item>
				<!-- radio -->
				<el-form-item prop="usertype">
					<el-radio-group v-model="loginForm.usertype">
						<el-radio border label="家长"></el-radio>
						<el-radio border label="老师"></el-radio>
						<el-radio border label="管理员"></el-radio>
					</el-radio-group>
				</el-form-item>
				<!-- 按钮区域 -->
				<el-form-item class="btns">
					<el-button type="primary" @click="login">登录</el-button>
					<el-button type="info" @click="resetLoginForm">重置</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			// 这是登录表单的数据绑定对象
			loginForm: {
				username: '',
				password: '',
				usertype: ''
			},
			// 这是表单的验证规则对象
			loginFormRules: {
				// 验证用户名是否合法
				username: [
					{required: true, message: '请输入登录账号', trigger: 'blur'},
					{
						pattern: /^1[3456789]\d{9}$/,
						message: '请输入正确格式手机号码',
						trigger: 'blur'
					}
				],
				// 验证密码是否合法
				password: [
					{required: true, message: '请输入登录密码', trigger: 'blur'},
					{
						pattern: /^[0-9a-zA_Z]{6,18}$/,
						message: '请输入正确格式密码，密码内容为数字、字母，至少6位',
						trigger: 'blur'
					}
				],
				usertype: [{required: true, message: '请选择角色', trigger: 'change'}]
			}
		}
	},
	methods: {
		// 点击重置按钮，重置登录表单
		resetLoginForm() {
			// console.log(this);
			this.$refs.loginFormRef.resetFields()
		},
		login() {
			this.$refs.loginFormRef.validate(async valid => {
				if (valid) {
					const res = await this.$service.login({
						username: this.loginForm.username,
						password: this.loginForm.password,
						usertype: this.loginForm.usertype
					})
					if (res) {
						if (res.code === 0) {
							if (res.data.usertype === '家长') {
								this.$router.push({
									path: '/teacher/parent'
								})
							}
							if (res.data.usertype === '老师') {
								this.$router.push({
									path: '/teacher/student'
								})
							}
							if (res.data.usertype === '管理员') {
								this.$router.push({
									path: '/teacher/manager'
								})
							}
						}
					}
				} else {
					return false
				}
			})
		}
	}
}
</script>

<style lang="less" scoped>
.login_container {
	background-color: #2b4b6b;
	height: 100%;
}

.login_box {
	width: 450px;
	height: 350px;
	background-color: #fff;
	border-radius: 3px;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);

	.avatar_box {
		height: 130px;
		width: 130px;
		border: 1px solid #eee;
		border-radius: 50%;
		padding: 10px;
		box-shadow: 0 0 10px #ddd;
		position: absolute;
		left: 50%;
		transform: translate(-50%, -50%);
		background-color: #fff;
		img {
			width: 100%;
			height: 100%;
			border-radius: 50%;
			background-color: #eee;
		}
	}
}

.login_form {
	position: absolute;
	bottom: 0;
	width: 100%;
	padding: 0 20px;
	box-sizing: border-box;
}

.btns {
	display: flex;
	justify-content: flex-end;
}
</style>
